/* tslint:disable */
/* eslint-disable */
/**
 * Workbook builder api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 *
 * @export
 * @interface HandlersExtensionDuration
 */
export interface HandlersExtensionDuration {
  /**
   *
   * @type {number}
   * @memberof HandlersExtensionDuration
   */
  duration?: number;
}
/**
 *
 * @export
 * @interface HandlersStart
 */
export interface HandlersStart {
  /**
   *
   * @type {number}
   * @memberof HandlersStart
   */
  expiry?: number;
  /**
   *
   * @type {boolean}
   * @memberof HandlersStart
   */
  is_gpu?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HandlersStart
   */
  is_gpu_32?: boolean;
  /**
   *
   * @type {string}
   * @memberof HandlersStart
   */
  workbook_deployment_config_id?: string;
}
/**
 *
 * @export
 * @interface HandlersWorkbookDetailedStatus
 */
export interface HandlersWorkbookDetailedStatus {
  /**
   *
   * @type {string}
   * @memberof HandlersWorkbookDetailedStatus
   */
  error_message?: string;
  /**
   *
   * @type {number}
   * @memberof HandlersWorkbookDetailedStatus
   */
  expiry?: number;
  /**
   *
   * @type {number}
   * @memberof HandlersWorkbookDetailedStatus
   */
  start_time?: number;
  /**
   *
   * @type {string}
   * @memberof HandlersWorkbookDetailedStatus
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof HandlersWorkbookDetailedStatus
   */
  token?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {HandlersStart} workbook workbook creation reqeust
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWorkbook: async (
      workbook: HandlersStart,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workbook' is not null or undefined
      assertParamExists("createWorkbook", "workbook", workbook);
      const localVarPath = `/api/v1/workbook`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        workbook,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} workbookId workbook id to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteWorkbook: async (
      workbookId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workbookId' is not null or undefined
      assertParamExists("deleteWorkbook", "workbookId", workbookId);
      const localVarPath = `/api/v1/workbook/{workbookId}`.replace(
        `{${"workbookId"}}`,
        encodeURIComponent(String(workbookId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} workbookId workbook id to extend
     * @param {HandlersExtensionDuration} extension workbook extension reqeust
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extendWorkbook: async (
      workbookId: string,
      extension: HandlersExtensionDuration,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workbookId' is not null or undefined
      assertParamExists("extendWorkbook", "workbookId", workbookId);
      // verify required parameter 'extension' is not null or undefined
      assertParamExists("extendWorkbook", "extension", extension);
      const localVarPath = `/api/v1/workbook/{workbookId}/extend`.replace(
        `{${"workbookId"}}`,
        encodeURIComponent(String(workbookId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        extension,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get running workbook sessions which are running with the given courseId and the user.
     * @param {string} courseId courseId to check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRunningWorkbookSessions: async (
      courseId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'courseId' is not null or undefined
      assertParamExists("getRunningWorkbookSessions", "courseId", courseId);
      const localVarPath = `/api/v1/workbook/course/{courseId}`.replace(
        `{${"courseId"}}`,
        encodeURIComponent(String(courseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} workbookId workbook id to check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkbookStatus: async (
      workbookId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workbookId' is not null or undefined
      assertParamExists("getWorkbookStatus", "workbookId", workbookId);
      const localVarPath = `/api/v1/workbook/{workbookId}`.replace(
        `{${"workbookId"}}`,
        encodeURIComponent(String(workbookId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {HandlersStart} workbook workbook creation reqeust
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createWorkbook(
      workbook: HandlersStart,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkbook(
        workbook,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} workbookId workbook id to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteWorkbook(
      workbookId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkbook(
        workbookId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} workbookId workbook id to extend
     * @param {HandlersExtensionDuration} extension workbook extension reqeust
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async extendWorkbook(
      workbookId: string,
      extension: HandlersExtensionDuration,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.extendWorkbook(
        workbookId,
        extension,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get running workbook sessions which are running with the given courseId and the user.
     * @param {string} courseId courseId to check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRunningWorkbookSessions(
      courseId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRunningWorkbookSessions(
          courseId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} workbookId workbook id to check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWorkbookStatus(
      workbookId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HandlersWorkbookDetailedStatus>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWorkbookStatus(workbookId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @param {HandlersStart} workbook workbook creation reqeust
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWorkbook(
      workbook: HandlersStart,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .createWorkbook(workbook, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} workbookId workbook id to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteWorkbook(workbookId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteWorkbook(workbookId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} workbookId workbook id to extend
     * @param {HandlersExtensionDuration} extension workbook extension reqeust
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extendWorkbook(
      workbookId: string,
      extension: HandlersExtensionDuration,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .extendWorkbook(workbookId, extension, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get running workbook sessions which are running with the given courseId and the user.
     * @param {string} courseId courseId to check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRunningWorkbookSessions(
      courseId: string,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .getRunningWorkbookSessions(courseId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} workbookId workbook id to check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkbookStatus(
      workbookId: string,
      options?: any,
    ): AxiosPromise<HandlersWorkbookDetailedStatus> {
      return localVarFp
        .getWorkbookStatus(workbookId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {HandlersStart} workbook workbook creation reqeust
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createWorkbook(workbook: HandlersStart, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .createWorkbook(workbook, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} workbookId workbook id to delete
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deleteWorkbook(workbookId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .deleteWorkbook(workbookId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} workbookId workbook id to extend
   * @param {HandlersExtensionDuration} extension workbook extension reqeust
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public extendWorkbook(
    workbookId: string,
    extension: HandlersExtensionDuration,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .extendWorkbook(workbookId, extension, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get running workbook sessions which are running with the given courseId and the user.
   * @param {string} courseId courseId to check
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getRunningWorkbookSessions(
    courseId: string,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .getRunningWorkbookSessions(courseId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} workbookId workbook id to check
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getWorkbookStatus(workbookId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getWorkbookStatus(workbookId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
